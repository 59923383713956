import { sxAjax } from '@/commons/ajax';

export default {
    namespace: 'warehouse',
    initialState: {
        warehouseData: [],
        warehouseInfo: {},
        fileCabinetTree: [],
        selectedRowsData: [],//选择的仓库编号信息
        authorizeCompany: [],//仓库授权公司
        authorizeCompanynew: [],//新仓库授权公司
        isChecked: true,//是否启用自定义授权公司
    },
    onWarehouseNumber: (fileValue, state) => {
        let { warehouseInfo } = state;
        warehouseInfo.warehouseNumber = fileValue
        return { warehouseInfo };
    },
    onWarehouseName: (fileValue, state) => {
        let { warehouseInfo } = state;
        warehouseInfo.warehouseName = fileValue
        return { warehouseInfo };
    },
    onWarehouseAddress: (fileValue, state) => {
        let { warehouseInfo } = state;
        warehouseInfo.warehouseAddress = fileValue
        return { warehouseInfo };
    },
    onWarehouseAreas: (fileValue, state) => {
        let { warehouseInfo } = state;
        warehouseInfo.warehouseAreas = fileValue
        return { warehouseInfo };
    },
    onArchivist: (fileValue, state) => {
        let { warehouseInfo } = state;
        warehouseInfo.archivist = fileValue
        return { warehouseInfo };
    },
    onWarehouseCapacity: (fileValue, state) => {
        let { warehouseInfo } = state;
        warehouseInfo.warehouseCapacity = fileValue
        return { warehouseInfo };
    },
    onCordon: (fileValue, state) => {
        let { warehouseInfo } = state;
        warehouseInfo.cordon = fileValue
        return { warehouseInfo };
    },
    onIsVirtualWarehouse: (fileValue, state) => {
        let { warehouseInfo } = state;
        warehouseInfo.isVirtualWarehouse = fileValue
        return { warehouseInfo };
    },
    onSelectedRow: (field, state) => {
        let { selectedRowsData } = state;
        selectedRowsData = field;
        return { selectedRowsData };
    },
    onSelectedCompany: (fileValue, state) => {
        let { warehouseInfo } = state;
        warehouseInfo.fileAuthorizes = fileValue
        return { warehouseInfo };
    },
    setReaRatio: (fileValue, state) => {
        let { warehouseInfo } = state;
        warehouseInfo.cordon = fileValue
        return { warehouseInfo };
    },
    setAuthorizeCompany: (fileValue, state) => {
        let { authorizeCompany } = state;
        authorizeCompany = fileValue
        return { authorizeCompany };
    },
    setChecked: (checked, state) => {
        let { isChecked } = state;
        isChecked = checked;
        return { isChecked };
    },
    getWarehouseList: {
        payload: (params) => sxAjax.get('api/services/app/Warehouse/GetWarehousePageList', params),
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                var data = { warehouseData: payload.result };
                return (data);
            },
            complete: (state, action) => {
                return ({ loading: false })
            },
        }
    },
    getWarehouseInfo: {
        payload: (params) => sxAjax.get('api/services/app/Warehouse/GetWarehouseDetail', params),
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                var data = { warehouseInfo: payload.result };
                return (data);
            },
            complete: (state, action) => {
                return ({ loading: false })
            },
        }
    },
    createWarehouse: {
        payload: (params) => sxAjax.post('api/services/app/Warehouse/AddWarehouse', params),
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {

            },
            complete: (state, action) => {
                return ({ loading: false })
            },
        }
    },
    updateWarehouse: {
        payload: (params) => sxAjax.post('api/services/app/Warehouse/ChangeWarehouse', params),
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {

            },
            complete: (state, action) => {
                return ({ loading: false })
            },
        }
    },
    removeWarehouse: {
        payload: (params) => sxAjax.post('api/services/app/Warehouse/RemoveWarehouse', params),
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {

            },
            complete: (state, action) => {
                return ({ loading: false })
            },
        }
    },
    getFileCabinetTree: {
        payload: (params) => sxAjax.get('api/services/app/FileCabinet/GetFileCabinetTree', params),
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                var data = { fileCabinetTree: payload.result };
                return (data);
            },
            complete: (state, action) => {
                return ({ loading: false })
            },
        }
    },
    CreateFileCabinetTree: {
        payload: (params) => sxAjax.post('api/services/app/FileCabinet/CreateWarehouseTree', params),
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {

            },
            complete: (state, action) => {
                return ({ loading: false })
            },
        }
    },
    updateFileCabinetTree: {
        payload: (params) => sxAjax.post('api/services/app/FileCabinet/ChangeFileCabinet', params),
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {

            },
            complete: (state, action) => {
                return ({ loading: false })
            },
        }
    },
    removeFileCabinet: {
        payload: (params) => sxAjax.post('api/services/app/FileCabinet/RemoveFileCabinet', params),
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {

            },
            complete: (state, action) => {
                return ({ loading: false })
            },
        }
    },
    getAuthorizeCompanyList: {
        payload: (params) => sxAjax.get('api/services/app/FileAuthorize/GetWarehouseListByWID', params),
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                var data = { authorizeCompany: payload.result };
                return (data);
            },
            complete: (state, action) => {
                return ({ loading: false })
            },
        }
    },
    GetCompanyList: {
        payload: (params) => sxAjax.post('api/services/app/OrganizationUnit/GetCompanyList', params),
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                // console.log('payload++++',payload);
                var data = { authorizeCompanynew: payload.result };
                return (data);
            },
            complete: (state, action) => {
                return ({ loading: false })
            },
        }
    },
    canSwitch: {
        payload: (params) => sxAjax.get('/api/services/app/Warehouse/HasFileAboveList'),
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                var data = {};
                return (data);
            },
            complete: (state, action) => {
                return ({ loading: false })
            },
        }
    },
    getCompanyNewList: {
        payload: (params) => sxAjax.get('api/services/app/Warehouse/GetAuthorizeCompanyList'),
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                var data = { authorizeCompanynew: payload.result };
                return (data);
            },
            complete: (state, action) => {
                return ({ loading: false })
            },
        }
    }
}