import {sxAjax} from '@/commons/ajax';

export default{
    namespace:'lendingLibrary',
    initialState:{
        lendingLibraryData:[],
        isShow:false,
        selectedRowKeys:[],
        selectedRows:[],
        applyDetailData:[],//借阅详细数据
        security:'',//密级
        showChild:false,//显示表单
        fileVisible:false,//显示申请单关联的文件组
        isPass:false,//借阅是否通过
    },
    onShowSp:(field,state)=>{
        let { isShow } = state;
        isShow=field
        return { isShow };
    },
    onSelectedRows:(field,state)=>{
        let { selectedRows } = state;
        selectedRows=field
        return { selectedRows };
    },
    onSelectedRowKeys:(field,state)=>{
        let { selectedRowKeys } = state;
        selectedRowKeys=field
        return { selectedRowKeys };
    },
    onSetSecurity:(field,state)=>{
        let{security}=state;
        security=field;
        return {security};
    },  
    onSetShowChild:(field,state)=>{
        let{showChild}=state;
        showChild=field;
        return {showChild};
    },
    onSetFileVisible:(field,state)=>{
        let{fileVisible}=state;
        fileVisible=field;
        return {fileVisible};
    },
    onSetPass:(field,state)=>{
        let{isPass}=state;
        isPass=field;
        return {isPass};
    },
    getLendingLibraryList:{
        payload:(params)=>sxAjax.get('api/services/app/BorrowingLibrary/GetBorrowingDataList',params),//api/services/app/LendingLibrary/GetLendingLibraryList
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                var data={lendingLibraryData:payload.result};
                return(data);
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    },
    removeLendingLibrary:{
        payload:(params)=>sxAjax.post('api/services/app/BorrowingLibrary/RemoveBorrowingData',params),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    },
    removeCarts:{
        payload:(params)=>sxAjax.post('api/services/app/LendingLibrary/RemoveCart',params),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    },
    AddApplyDetail:{
        // payload:(params)=>sxAjax.post('api/services/app/ApplicationForm/SubmitApplication',params),
        payload:(params)=>sxAjax.post('api/services/app/ApplicationForm/ZbSubmitApplication',params),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    },
    getApplyDetail:{
        payload:(params)=>sxAjax.get('api/services/app/EaaApplicationProcess/GetAnnexList',params),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                var data={applyDetailData:payload.result};
                return(data);
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    },
    GetAnnexByTaskMstId:{
        payload:(params)=>sxAjax.get('api/services/app/EaaApplicationProcess/GetAnnexByTaskMstId',params),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                var data={applyDetailData:payload.result};
                return(data);
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    },
    validateSp:{
        payload:(params)=>sxAjax.post('api/services/app/ApplicationForm/ValidateApplication',params),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    },
}