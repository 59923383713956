import { sxAjax } from '@/commons/ajax';
import { stringify } from 'qs';

declare var abp: any;
export default {
    namespace: 'staffing',
    initialState: {
        user: {},
        memberedOrganizationUnits: '',
        headImg: '',
        loginBgImage: '',
    },
    onChangeFileUserName: (fileValue, state) => {
        let { user } = state;
        user.name = fileValue
        return { user };
    },
    onChangeFilesurname: (fileValue, state) => {
        let { user } = state;
        user.surname = fileValue
        return { user };
    },
    onChangeFilesPhoneNumber: (fileValue, state) => {
        let { user } = state;
        user.phoneNumber = fileValue
        return { user };
    },
    onChangeFilesEmailAddress: (fileValue, state) => {
        let { user } = state;
        user.emailAddress = fileValue
        return { user };
    },
    getUser: {//获取用户信息
        payload: (params) => {
            return sxAjax.get('api/services/app/User/GetUserForEdit', params);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                if (payload.result.user != undefined) {
                    let user = {
                        id: payload.result.user.id,
                        name: payload.result.user.name,
                        surname: payload.result.user.surname,
                        userName: payload.result.user.userName,
                        emailAddress: payload.result.user.emailAddress,
                        phoneNumber: payload.result.user.phoneNumber,
                    }
                    return ({ user, memberedOrganizationUnits: payload.result.memberedOrganizationUnits });
                }
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    SaveUpdateUser: {//更新用户信息
        payload: (params) => {
            return sxAjax.put('api/services/app/Profile/UpdateCurrentUserProfile', params);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    getUserHeader: {
        payload: (params) => {
            return sxAjax.get('api/services/app/Profile/GetProfilePicture', params);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                return ({ headImg: payload.result });
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    uploadHeadPic: {
        payload: (params) => {
            return sxAjax.put('api/services/app/Profile/UpdateProfilePicture', params);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    getOriginalLoginBgImage: {
        payload: (params) => {
            return sxAjax.get(abp.setting.get("App.Api.Default.Host") + `File/GetOriginalImage?docId=${params}`)
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                var data = { loginBgImage: payload.result };
                return (data);
            },
            complete: (state, action) => {
                return ({ loading: false })
            },
        }
    },
}