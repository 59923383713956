import React, { Component } from "react";
import { Form, notification, Icon } from "antd";
import config from "@/commons/config-hoc";
import { FormElement, ModalContent } from "@/library/components";
import PageContent from "@/layouts/page-content";

@config({
    ajax: true,
    connect: (state) => ({ loginUser: state.system.loginUser }),
    modal: {
        title: "修改密码",
        width: 420,
    },
})
@Form.create()
export default class ModifyPassword extends Component {
    state = {
        loading: false,
    };

    handleOk = (e) => {
        e.preventDefault();

        const { loading } = this.state;
        if (loading) return;

        const {
            onOk,
            form: { validateFieldsAndScroll },
        } = this.props;

        validateFieldsAndScroll((err, values) => {
            if (!err) {
                if (values.newPassword === values.reNewPassword) {
                    this.setState({ loading: true });
                    this.props.ajax
                        .post("/api/services/app/Profile/ChangePassword", {
                            currentPassword: values.oldPassword,
                            newPassword: values.newPassword,
                        })
                        .then(() => {
                            if (onOk) onOk();
                        })
                        .catch(() => {
                            notification.open({
                                message: "异常",
                                description: "原密码错误",
                                icon: (
                                    <Icon
                                        type="close-circle"
                                        style={{ color: "red" }}
                                    />
                                ),
                            });
                            return;
                        })
                        .finally(() => this.setState({ loading: false }));
                } else {
                    notification.open({
                        message: "提示",
                        description: "新密码与确认密码不一致",
                        icon: (
                            <Icon
                                type="close-circle"
                                style={{ color: "red" }}
                            />
                        ),
                    });
                    return;
                }
            }
        });
    };

    handleCancel = () => {
        const { onCancel } = this.props;
        if (onCancel) onCancel();
    };

    render() {
        const { loginUser, form } = this.props;
        const id = loginUser?.id;
        const { loading } = this.state;
        const labelWidth = 100;
        return (
            <ModalContent
                loading={loading}
                surplusSpace={false}
                onCancel={this.handleCancel}
                onOk={this.handleOk}
            >
                <PageContent>
                    <Form>
                        <FormElement
                            form={form}
                            type="hidden"
                            field="id"
                            decorator={{ initialValue: id }}
                        />
                        <FormElement
                            form={form}
                            label="原密码"
                            labelWidth={labelWidth}
                            type="password"
                            field="oldPassword"
                            decorator={{
                                rules: [
                                    {
                                        required: true,
                                        message: "请输入原密码！",
                                    },
                                    {
                                        min: 6,
                                        message: "密码不得少于6字符",
                                    },
                                ],
                                validateTrigger: "onBlur",
                            }}
                        />
                        <FormElement
                            form={form}
                            label="新密码"
                            labelWidth={labelWidth}
                            type="password"
                            field="newPassword"
                            decorator={{
                                rules: [
                                    {
                                        required: true,
                                        message: "请输入新密码！",
                                    },
                                    {
                                        min: 6,
                                        message: "密码不得少于6字符",
                                    },
                                ],
                                validateTrigger: "onBlur",
                            }}
                        />
                        <FormElement
                            form={form}
                            label="确认密码"
                            labelWidth={labelWidth}
                            type="password"
                            field="reNewPassword"
                            decorator={{
                                rules: [
                                    {
                                        required: true,
                                        message: "请输入确认密码！",
                                    },
                                    {
                                        min: 6,
                                        message: "密码不得少于6字符",
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            const newPassword = form.getFieldValue(
                                                "newPassword"
                                            );
                                            if (
                                                value &&
                                                newPassword &&
                                                newPassword !== value
                                            ) {
                                                return callback(
                                                    "确认密码要与新密码相同"
                                                );
                                            }

                                            return callback();
                                        },
                                    },
                                ],
                                validateTrigger: "onBlur",
                            }}
                        />
                    </Form>
                </PageContent>
            </ModalContent>
        );
    }
}
