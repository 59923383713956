import { sxAjax } from '@/commons/ajax';
import { createTree } from '@/lib/utils';

export default {
    namespace: 'organization',
    initialState: {
        //#region 新方法
        organizationTreeData: [],
        organizationTreeDataAll: [],
        //#endregion
        orgData: [],
        organizationData: [],
        organizationDataByCodes: [],
        organizationCodes: [],
        organizationUnitUsersData: [],
        organizationUnitUsersDataByCodes: [],
        findUsersLoading: false,
        organizationUnitUsersLoading: false,
        findUsersData: [],
        organizationDataLoading: false,
    },
    getOrganizationUnits: {
        payload: (params) => sxAjax.get('api/services/app/OrganizationUnit/GetOrganizationUnits', params),
        reducer: {
            pending: (state, action) => ({ organizationDataLoading: true }),
            resolve(state, { payload = {} }) {
                const { total, items = [] } = payload.result;
                function generateTextOnTree(ou) {
                    return ou.displayName + '(' + ou.memberCount + ')';
                }

                let data = createTree(items, 'parentId', 'id', null, 'children',
                    [
                        {
                            target: 'displayName',
                            targetFunction(item) {
                                return generateTextOnTree(item);
                            }
                        },
                        {
                            target: 'key',
                            targetFunction(item) {
                                return item.id + "";
                            }
                        }
                    ]
                );
                return {
                    organizationData: data,
                    orgData: items
                };
            },
            complete: (state, action) => {
                return ({ organizationDataLoading: false })
            },
        }
    },
    getOrganizationUnitsByCodes: {
        payload: (params) => sxAjax.post('api/services/app/OrganizationUnit/GetOrganizationUnitsByCodes', params),
        reducer: {
            pending: (state, action) => ({ organizationDataLoading: true }),
            resolve(state, { payload = {} }) {
                const { total, items = [] } = payload.result;

                let data = createTree(items, 'parentId', 'id', null, 'children',
                    [
                        {
                            target: 'key',
                            targetFunction(item) {
                                return item.id + "";
                            }
                        }
                    ]
                );
                return {
                    organizationDataByCodes: data,
                };
            },
            complete: (state, action) => {
                return ({ organizationDataLoading: false })
            },
        }
    },
    getOrganizationUnitCodesByCodes: {
        payload: (params) => sxAjax.post('api/services/app/OrganizationUnit/GetOrganizationUnitCodesByCodes', params),
        reducer: {
            pending: (state, action) => ({ organizationDataLoading: true }),
            resolve(state, { payload = {} }) {
                return {
                    organizationCodes: payload.result
                };
            },
            complete: (state, action) => {
                return ({ organizationDataLoading: false })
            },
        }
    },
    getOrganizationUnitUsers: {
        payload: (params) => sxAjax.get('api/services/app/OrganizationUnit/GetOrganizationUnitUsers', params),
        reducer: {
            pending: (state, action) => ({ organizationUnitUsersLoading: true }),
            resolve(state, { payload = {} }) {
                return {
                    organizationUnitUsersData: payload.result
                }
            },
            complete: (state, action) => ({ organizationUnitUsersLoading: false }),
        }
    },
    getOrganizationUnitUsersByCodes: {
        payload: (params) => sxAjax.post('api/services/app/OrganizationUnit/GetOrganizationUnitUsersByCodes', params),
        reducer: {
            pending: (state, action) => ({ organizationUnitUsersLoading: true }),
            resolve(state, { payload = {} }) {
                return {
                    organizationUnitUsersDataByCodes: payload.result
                }
            },
            complete: (state, action) => ({ organizationUnitUsersLoading: false }),
        }
    },
    MoveOrganizationUnit: {
        payload: (params) => sxAjax.post('api/services/app/OrganizationUnit/MoveOrganizationUnit', params),
        reducer: {
            pending: (state, action) => ({ organizationDataLoading: true }),
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ organizationDataLoading: false }),
        }
    },
    DeleteOrganizationUnit: {
        payload: (params) => sxAjax.del('api/services/app/OrganizationUnit/DeleteOrganizationUnit', params),
        reducer: {
            pending: (state, action) => ({ organizationDataLoading: true }),
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ organizationDataLoading: false }),
        }
    },
    FindUsers: {
        payload: (params) => sxAjax.post('api/services/app/OrganizationUnit/FindUsers', params),
        reducer: {
            pending: (state, action) => ({ findUsersLoading: true }),
            resolve(state, { payload = {} }) {
                return {
                    findUsersData: payload.result
                }
            },
            complete: (state, action) => ({ findUsersLoading: false }),
        }
    },
    RemoveUserFromOrganizationUnit: {
        payload: (params) => sxAjax.del('api/services/app/OrganizationUnit/RemoveUserFromOrganizationUnit', params),
        reducer: {
            pending: (state, action) => ({ organizationDataLoading: true }),
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ organizationDataLoading: false }),
        }
    },
    AddUsersToOrganizationUnit: {
        payload: (params) => sxAjax.post('api/services/app/OrganizationUnit/AddUsersToOrganizationUnit', params),
        reducer: {
            pending: (state, action) => ({ organizationDataLoading: true }),
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ organizationDataLoading: false }),
        }
    },
    CreateOrganizationUnit: {
        payload: (params) => sxAjax.post('api/services/app/OrganizationUnit/CreateOrganizationUnit', params),
        reducer: {
            pending: (state, action) => ({ organizationDataLoading: true }),
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ organizationDataLoading: false }),
        }
    },
    UpdateOrganizationUnit: {
        payload: (params) => sxAjax.put('api/services/app/OrganizationUnit/UpdateOrganizationUnit', params),
        reducer: {
            pending: (state, action) => ({ organizationDataLoading: true }),
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ organizationDataLoading: false }),
        }
    },
    SetUserIsManagerOnOrganizationUnit: {
        payload: (params) => sxAjax.post('api/services/app/OrganizationUnit/SetUserIsManagerOnOrganizationUnit', params),
        reducer: {
            pending: (state, action) => ({ organizationDataLoading: true }),
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ organizationDataLoading: false }),
        }
    },
    //#region 新方法
    getTreeData: {
        payload: (params) => sxAjax.get('api/services/app/OrganizationUnit/GetTreeData', params),
        reducer: {
            pending: (state, action) => ({ organizationDataLoading: true }),
            resolve(state, { payload = {} }) {
                const { total, items = [] } = payload.result;
                function generateTextOnTree(ou) {
                    return ou.displayName;
                }

                let data = createTree(items, 'parentId', 'id', null, 'children',
                    [
                        {
                            target: 'displayName',
                            targetFunction(item) {
                                return generateTextOnTree(item);
                            }
                        },
                        {
                            target: 'key',
                            targetFunction(item) {
                                return item.id + "";
                            }
                        }
                    ]
                );

                return {
                    organizationTreeData: data,
                    organizationTreeDataAll: items
                };
            },
            complete: (state, action) => {
                return ({ organizationDataLoading: false })
            },
        }
    },
    //#endregion
}