import React, {Component} from 'react';
import PropTypes from 'prop-types';
import logo from './logo.png';
import './style.less';

export default class Logo extends Component {
    static propTypes = {
        min: PropTypes.bool,
    };
    static defaultProps = {
        logo: logo,
        title: 'Doc Park',
        min: false,
    };

    render() {
        const {min, title, ...others} = this.props;
        return (
            <div styleName="logo" className={min?'logo-collapsed':''}>
                {/* <img src={this.props.src} alt="logo"/> */}
        {/* <h1 className={min ? '' : 'title-hide'}>{title.substring(0,1)}</h1> */}
                <h1 {...others} className={min ? 'title-hide' : ''}>{title}</h1>
            </div>
        );
    }
}
