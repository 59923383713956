// 此文件为生成文件，不要直接编辑
import _organization from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/admin/organization.js';
import _role from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/admin/role.js';
import _user from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/admin/user.js';
import _dashReport from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/dash-report/dashReport.js';
import _acquisition from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/document/acquisition.js';
import _metadataDesign from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/document/metadataDesign.js';
import _reportDesign from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/document/reportDesign.js';
import _archiveBook from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/EAA/archivesBookloading/archiveBook.js';
import _archivesList from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/EAA/archivesCards/archivesList.js';
import _archiveColl from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/EAA/archivesCollect/archiveColl.js';
import _listForm from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/EAA/BasicPage/listForm.js';
import _borrowDetail from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/EAA/borrowDetail/borrowDetail.js';
import _ComprehensivelistDesign from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/EAA/ComprehensiveQuery/ComprehensivelistDesign.js';
import _AccountlistDesign from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/EAA/DocumentQuery/account/AccountlistDesign.js';
import _AccountbooklistDesign from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/EAA/DocumentQuery/accountbook/AccountbooklistDesign.js';
import _AccountotherlistDesign from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/EAA/DocumentQuery/accountother/AccountotherlistDesign.js';
import _AccountreportlistDesign from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/EAA/DocumentQuery/accountreport/AccountreportlistDesign.js';
import _DocumentQuery from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/EAA/DocumentQuery/DocumentQuery.js';
import _eaaFile from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/EAA/file/eaa-file.js';
import _lendingLibrary from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/EAA/LendingLibrary/lendingLibrary.js';
import _Portal from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/EAA/portals/Portal.js';
import _eaaOrganization from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/EAA/publics/eaaOrganization.js';
import _listDesign from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/EAA/publics/listDesign.js';
import _treeDesign from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/EAA/publics/treeDesign.js';
import _returnReg from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/EAA/returnRegistra/returnReg.js';
import _putaway from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/EAA/shelf/putaway.js';
import _warehouse from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/EAA/warehouses/warehouse.js';
import _flow from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/flowCenter/flow.js';
import _comment from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/form/comments/comment.js';
import _formEditor from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/form/formEditor.js';
import _formDesign from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/form/workflow/formDesign.js';
import _menu from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/menu.js';
import _menus from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/menus/menus.js';
import _notification from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/notifications/notification.js';
import _page from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/page.js';
import _settings from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/settings.js';
import _side from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/side.js';
import _system from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/system.js';
import _agent from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/users/agent.js';
import _staffing from '/root/workspace/DocparkV2_Docpark-App_SeTx/src/models/users/staffing.js';

export const organization = _organization;
export const role = _role;
export const user = _user;
export const dashReport = _dashReport;
export const acquisition = _acquisition;
export const metadataDesign = _metadataDesign;
export const reportDesign = _reportDesign;
export const archiveBook = _archiveBook;
export const archivesList = _archivesList;
export const archiveColl = _archiveColl;
export const listForm = _listForm;
export const borrowDetail = _borrowDetail;
export const ComprehensivelistDesign = _ComprehensivelistDesign;
export const AccountlistDesign = _AccountlistDesign;
export const AccountbooklistDesign = _AccountbooklistDesign;
export const AccountotherlistDesign = _AccountotherlistDesign;
export const AccountreportlistDesign = _AccountreportlistDesign;
export const DocumentQuery = _DocumentQuery;
export const eaaFile = _eaaFile;
export const lendingLibrary = _lendingLibrary;
export const Portal = _Portal;
export const eaaOrganization = _eaaOrganization;
export const listDesign = _listDesign;
export const treeDesign = _treeDesign;
export const returnReg = _returnReg;
export const putaway = _putaway;
export const warehouse = _warehouse;
export const flow = _flow;
export const comment = _comment;
export const formEditor = _formEditor;
export const formDesign = _formDesign;
export const menu = _menu;
export const menus = _menus;
export const notification = _notification;
export const page = _page;
export const settings = _settings;
export const side = _side;
export const system = _system;
export const agent = _agent;
export const staffing = _staffing;

