import React, { Component } from "react";
import PropTypes from "prop-types";
import Breadcrumb from "../breadcrumb";
import "./style.less";
import { Row, Col } from "antd";

export default class PageHead extends Component {
    static propTypes = {
        title: PropTypes.any,
        breadcrumbs: PropTypes.array,
    };
    static defaultProps = {
        title: "",
        breadcrumbs: [],
    };

    render() {
        let { title, breadcrumbs } = this.props;

        if (!breadcrumbs || breadcrumbs.length == 0) {
            breadcrumbs = [];
        }
        if (typeof title === "object" && title.text) title = title.text;
        breadcrumbs.push({icon: "", path: "", key: "title", text: title});
        return (
            <Row styleName="page-header">
                <Col span={8}>
                    <div styleName="location">我的位置:</div>
                    <div styleName="breadcrumb">
                        <Breadcrumb dataSource={breadcrumbs} />
                    </div>
                </Col>
                <Col span={10} offset={6}>
                    <div styleName="page-header-extend">
                        {
                            this.props.children
                        }
                    </div>
                </Col>
            </Row>
        );
    }
}
