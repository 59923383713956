import React, { Component } from 'react';
import { Icon, Tooltip,notification } from 'antd';
import { Link } from 'react-router-dom';
import './index.less';
import { differenceBy } from 'lodash';
import { render } from 'nprogress';
import { connect } from "@/models/index";

declare var abp: any;

let count = 0;
export default class HeaderNotices extends Component {

    openNotificationWithIcon = (type,description) => {
        var content=<div dangerouslySetInnerHTML={{__html: description}}></div>;
        notification[type]({
            message:"下载通知",
            description:content,
            duration: 0,
        });
    };

    componentDidMount() {
        var that=this;
        abp.event.on("abp.notifications.received", function (userNotification) {
            count++;
            let tip = document.getElementById("tips");
            tip.innerText = count;
            var props=userNotification.notification.data.properties||'';
            if(props.Message!=undefined)
            {
                if(props.Message.sourceName=="DownFile")
                {
                    if(props.mode=="success")
                        that.openNotificationWithIcon('success',props.Message.name);//'warning'//'success'//'error'
                    else{
                        that.openNotificationWithIcon('error',props.Message.name);
                    }
                }
            }
            console.log('count',count);
            // let tip = document.getElementById('tips');
            // tip.innerText=count;
            that.props.action.notification.getNotificationList({
                state: null,
                MaxResultCount: 10,
                SkipCount: 0
            });
        });
    }

    render() {
        const { className, tips } = this.props;
        count = tips;
        return (
            <div className={className}>
                <Tooltip placement="bottom" title={'通知'}>
                    <div style={{ display: 'flex', alignItems: 'center', height: '30px', lineHeight: '30px',position:'relative' }}>
                        <Link to="/notifications" style={{ color: 'white' }}><Icon type="bell" /></Link>
                        {/* <span id="tips" className="label-warning">{count}</span> */}
                        { count !== 0 && (
                            <span id="tips" className="label-warning" ></span>
                        )}
                    </div>
                </Tooltip>
            </div>
        );
    }
}
