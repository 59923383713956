import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon, Button } from "antd";
import Link from "../page-link";
import Logo from "../logo";
import HeaderUser from "../header-user";
import HeaderMenu from "../header-menu";
import HeaderFullScreen from "../header-full-screen";
import LanguageSelect from "../header-language-select";
import ThemeColorPicker from "../header-color-picker";
import HeaderNotices from "../header-notice";
import { connect } from "../../models/index";
import { PAGE_FRAME_LAYOUT } from "@/models/settings";
import Breadcrumb from "../breadcrumb";
import "./style.less";
import { L } from "@/lib/abpUtility";
import { isGranted } from "@/lib/abpUtility";
import { toAdmin } from "@/commons";
import { FormattedMessage } from "react-intl";

declare var abp: any;
@connect((state) => {
    const { menus, topMenu } = state.menu;
    const {
        show: showSide,
        width,
        collapsed,
        collapsedWidth,
        dragging,
    } = state.side;
    const { breadcrumbs } = state.page;
    const { pageFrameLayout } = state.settings;
    const { unreadCount } = state.notification;

    return {
        menus,
        topMenu,

        showSide,
        sideWidth: width,
        sideCollapsed: collapsed,
        sideCollapsedWidth: collapsedWidth,
        sideDragging: dragging,
        breadcrumbs,
        unreadCount,
        layout: pageFrameLayout,
    };
})
export default class Header extends Component {
    static propTypes = {
        layout: PropTypes.string,
        theme: PropTypes.string,
    };

    static defaultProps = {
        layout: PAGE_FRAME_LAYOUT.SIDE_MENU, // top-side-menu top-menu side-menu
        theme: "default", // default dark
    };
    componentDidMount() {
        this.props.action.notification.getNotificationList({
            state: 0,
            MaxResultCount: 10,
            SkipCount: 0,
        });
    }

    handleToggle = () => {
        const { sideCollapsed } = this.props;
        this.props.action.side.setCollapsed(!sideCollapsed);
    };

    handleOpenAppManager = () => {
        toAdmin();
    };

    render() {
        let {
            layout,
            menus, // 所有的菜单数据
            topMenu, // 当前页面选中菜单的顶级菜单
            sideCollapsed,
            sideCollapsedWidth,
            sideWidth,
            sideDragging,
            breadcrumbs,
            children,
            unreadCount,
        } = this.props;

        sideWidth = sideCollapsed ? sideCollapsedWidth : sideWidth;

        const isTopSideMenu = layout === PAGE_FRAME_LAYOUT.TOP_SIDE_MENU;
        const isTopMenu = layout === PAGE_FRAME_LAYOUT.TOP_MENU;
        const isSideMenu = layout === PAGE_FRAME_LAYOUT.SIDE_MENU;
        const showToggle = isTopSideMenu || isSideMenu;
        const showMenu = isTopSideMenu || isTopMenu;

        let topMenus = menus;
        if (isTopSideMenu) {
            topMenus =
                menus &&
                menus.map((item) => ({
                    key: item.key,
                    text: item.text,
                    path: item.path,
                    icon: item.icon,
                }));
        }
        if (isTopMenu) {
            topMenus = menus;
        }

        let transitionDuration = sideDragging ? "0ms" : "300ms";

        const theme = this.props.theme || ((isTopSideMenu || isSideMenu) ? 'default' : 'dark');
        var goBackManager=false;
        if(isGranted("App.Permissions.Admin.Login"))
            {
                goBackManager=true;
            }
            const { CustomizeLoginPage } = abp.setting.values;
            const setting = JSON.parse(CustomizeLoginPage);
            // const logo = abp.setting.values['App.Api.Default.Host'] + '/enterprise/'+setting?.Logo;
            const logo = abp.setting.values['App.General.HomeUrl'];
        return (
            <div id="header" styleName="header" data-theme={theme}>
                <div styleName="logo-container" id="logo-container" style={{flex: `0 0 ${sideWidth}px`, transitionDuration}}>
                    <Link to={logo}>
                        <Logo
                            min={sideCollapsed}
                            title={setting.SystemName}
                            src={logo}
                        />
                    </Link>
                </div>
                {showToggle ? (
                    <Icon
                        className="header-trigger"
                        styleName="trigger"
                        type={sideCollapsed ? "menu" : "menu"}
                        onClick={this.handleToggle}
                        style={
                            theme === "dark"
                                ? { color: "#fff", backgroundColor: "#222" }
                                : null
                        }
                    />
                ) : null}
                {children ? (
                    <div styleName="center">{children}</div>
                ) : (
                    <div styleName="center">
                        {showMenu ? (
                            <HeaderMenu
                                theme={theme}
                                dataSource={topMenus}
                                selectedKeys={[topMenu && topMenu.key]}
                            />
                        ) : null}
                        {isSideMenu ? (
                            <div style={{ marginLeft: 16 }}>
                                <Breadcrumb
                                    theme={theme}
                                    dataSource={breadcrumbs}
                                />
                            </div>
                        ) : null}
                    </div>
                )}

                <div styleName="right">
                    {goBackManager ? (
                        <div
                            styleName="button"
                            className="header-button"
                            onClick={this.handleOpenAppManager}
                        >
                            <FormattedMessage id="enterBackManagement" />
                        </div>
                    ) : null}
                    <LanguageSelect
                        styleName="action"
                        className="header-action"
                    ></LanguageSelect>
                    <HeaderNotices
                        styleName="action"
                        tips={unreadCount}
                        className="header-action"
                    ></HeaderNotices>
                    <ThemeColorPicker
                        styleName="action"
                        className="header-action"
                    />
                    <HeaderUser
                        styleName="action"
                        className="header-action"
                        theme={theme}
                    />
                    <HeaderFullScreen
                        styleName="action"
                        className="header-action"
                    />
                </div>
            </div>
        );
    }
}
