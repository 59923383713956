const zh_CN = {
    query: "查询",
    reset: "重置",
    addCart: "加入借阅库",
    addSuccess: "添加成功",
    addFail: "添加失败",
    home: "首页",
    dicQuery: "目录查询",
    position: "我的位置",
    docType: "文档类型",
    fileLevel: "档案层级",
    EAF: "电子会计档案",
    enterBackManagement: "进入后台管理",
    templatePrintConf: "模板打印配置",
    documentType: "档案类型",
    epidermis: "表皮",
    spine: "书脊",
    save: "保存",
    totalItems: "共 {total} 条数据",
    book: "册",
    offBook: "册内",
    saveSuccess: "保存成功",
    saveFail: "保存失败",
    reportConfig: "报表配置",
    form: "表单",
    name: "名称",
    metadataField: "元数据字段",
    fileCollect: "档案收集",
    fieldConfig: "字段配置",
    confirm: "确定",
    cancel: "取消",
    fileBook: "档案装册",
    archive: "档案归档",
    fileTransfer: "档案移交",
    fileReShelf: "档案待上架",
    fileShelf: "档案上架",
    printList: "打印清单",
    operation: "操作",
    formName: "表单名称",
    id: "ID",
    updateFieldName: "修改字段名称",
    delSuccess: "删除成功",
    delFail: "删除失败",
    modifyFilterCondition: "修改筛选条件",
};

export default zh_CN;
