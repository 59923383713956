import React, { Component } from "react";
import PropTypes from "prop-types";
import { Menu, Icon } from "antd";
import { renderNode } from "@/library/utils/tree-utils";
import Link from "../page-link";
import { L, isGranted } from "../../lib/abpUtility";
import "./style.less";

const SubMenu = Menu.SubMenu;

export default class SideMenu extends Component {
    static propTypes = {
        dataSource: PropTypes.array, // 菜单数据
        theme: PropTypes.string, // 主题
        collapsed: PropTypes.bool, // 是否收起
        openKeys: PropTypes.array, // 打开菜单keys
        selectedKeys: PropTypes.array, // 选中菜单keys
        onOpenChange: PropTypes.func, // 菜单打开关闭时触发
    };

    static defaultProps = {
        dataSource: [],
        theme: "dark",
        collapsed: false,
        openKeys: [],
        selectedKeys: [],
        onOpenChange: () => true,
    };

    handleOpenChange = (openKeys) => {
        this.props.onOpenChange(openKeys);
    };

    renderMenus() {
        const { dataSource } = this.props;
        if (dataSource && dataSource.length) {
            // 删除借阅申请单和销毁
            const menuData = [...dataSource].filter(
                (item) => item?.key !== "c524223e-d160-9c55-fefb-b15e2fcdbe43" && item?.key !== "23ff8ab6-c2d7-3d1f-c6e5-957749ab03bb"
            );

            return renderNode(menuData, (item, children) => {
                const { key, path, text, icon, target, url } = item;

                let title = <span>{L(text)}</span>;
                if (icon)
                    title = (
                        <span>
                            <Icon type={icon} />
                            <Icon type="home" style={{ display: "none" }} />
                            <span>{L(text)}</span>
                        </span>
                    );

                if (children) {
                    title = (
                        <span>
                            <Icon type={"folder"} />
                            <Icon type="home" style={{ display: "none" }} />
                            <span>{L(text)}</span>
                        </span>
                    );
                    return (
                        <SubMenu key={key} title={title}>
                            {children}
                        </SubMenu>
                    );
                }
                if (item.permission && !isGranted(item.permission)) return null;
                return (
                    <Menu.Item
                        style={{
                            margin: "0 auto",
                            padding: "0 auto",
                            height: "44px",
                            lineHeight: "44px",
                        }}
                        key={key}
                    >
                        {target ? (
                            <a href={url} target={target}>
                                {title}
                            </a>
                        ) : (
                            <Link
                                to={{
                                    pathname: path,
                                    state: { from: "menu" },
                                }}
                            >
                                {title}
                            </Link>
                        )}
                    </Menu.Item>
                );
            });
        }
        return null;
    }

    render() {
        let { theme, collapsed, openKeys, selectedKeys } = this.props;
        const menuProps = collapsed
            ? {}
            : {
                openKeys,
            };
        return (
            <div styleName="side-menu">
                <Menu
                    {...menuProps}
                    selectedKeys={selectedKeys}
                    mode="inline"
                    theme={theme}
                    inlineCollapsed={collapsed}
                    onOpenChange={this.handleOpenChange}
                >
                    {this.renderMenus()}
                </Menu>
            </div>
        );
    }
}
