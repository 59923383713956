import {sxAjax} from '@/commons/ajax';

/*
* 菜单数据 返回Promise各式，支持前端硬编码、异步获取菜单数据
* */
export default function getMenus(userId) {
    // TODO 根据userId获取菜单数据 或在此文件中前端硬编码菜单
    return Promise.resolve(sxAjax.get("api/services/app/NaviagationAppServices/GetNaviagationMenus").then(res=>{ return res.result;}));
    // return Promise.resolve([
    //     {"key":"bbf64e3a-b1ca-ec8f-fe7f-7299fbe845ef","text":"测试222","path":"","target":'',"order":0},
    //     {"key":"d8aff143-f3e5-d4f4-1881-ceeac53a5ad6","text":"测试流程","icon":null,
    //     "path":"/example/ajax","target":null,"order":1000,
    //     "parentKey":"bbf64e3a-b1ca-ec8f-fe7f-7299fbe845ef","permission":null,"url":null},
    //     {key: 'bpm', text: '工作流', icon: 'ant-design', path: '/', target: '', order: 2000},
    //     {key: 'bpm-newTask', text: '新建流程',parentKey:"bpm", icon: 'ant-design', path: '/', target: '', order: 2000},
    // ]);

}
