/* eslint-disable default-case */
import { sxAjax } from "@/commons/ajax";
declare var abp: any;
export default {
    namespace: "formDesign",
    initialState: {
        data: null, //设计器下的所有节点
        properties: {
            //表单属性
            labelStyle: "row",
            layout: "3", //布局 单列、双列、三列
            rules: [], //提交前校验事件
            formEvents: [], //前端事件
            hasFlow: true, //是否有流程
            hasCache: false, //是否缓存
            default: "1", //不可见字段赋值,
            remarkFormula: {}, //摘要
        },
        values: {
            //整个表单的值对象
            data: {},
        },
        dataLink: {
            data: {},
        },
        loadComplete: false, //加载完成再渲染设计器
        fields: [], //表单所有数据库字段
        loading: false, //加载中
        formId: "", //表单id
        formName: "", //表单名称
        mstId: "", //数据masterId
        formType: "", //表单类型
        Manager: false,//数据管理需要用到
        cacheData: {}, //数据暂存
        process: {}, //流程信息
        task: {}, //任务信息
        permissionType: {
            publish: 1, ///表单发起的权限类型
            view: 2, ///浏览流程的权限类型
            manager: 3, ///管理流程的类型
        },
        viewPermission: [], //浏览流程权限
        managerPermission: [], //管理全部流程,
        showSuccess: false, //提交成功后显示 成功的组件
        showFailed: false, //显示提交失败的界面
        error: "", //失败消息
        print: false, //打印
        reLoading: false, //重新加载组件,
        searchList: [], //列表设计-搜索选中标签
        Showstable: [], //列表设计-表格选中标签,
        queryData: {},
        FileType: "", //档案类型
        activeKey: "1", //档案收集修改tabkey
        showFileUpload: false,
    },
    /**组件卸载 */
    setUnmount: () => {
        return {
            data: null,
            properties: {
                //表单属性
                labelStyle: "row",
                layout: "3", //布局 单列、双列、三列
                rules: [], //提交前校验事件
                formEvents: [], //前端事件
                hasFlow: true, //是否有流程
                hasCache: false, //是否缓存
                default: "1", //不可见字段赋值,
                remarkFormula: {}, //摘要
            },
            values: {
                //整个表单的值对象
                data: {},
            },
            dataLink: {
                data: {},
            },
            loadComplete: false, //加载完成再渲染设计器
            fields: [], //表单所有数据库字段
            loading: false, //加载中
            formId: "", //表单id
            formName: "", //表单名称
            mstId: "", //数据masterId
            formType: "", //表单类型
            process: {}, //流程信息
            task: {}, //任务信息
            permissionType: {
                publish: 1, ///表单发起的权限类型
                view: 2, ///浏览流程的权限类型
                manager: 3, ///管理流程的类型
            },
            viewPermission: [], //浏览流程权限
            managerPermission: [], //管理全部流程,
            showSuccess: false, //提交成功后显示 成功的组件
            showFailed: false, //显示提交失败的界面
            error: "", //失败消息
            print: false, //打印
            reLoading: false, //重新加载组件,
            searchList: [], //列表设计-搜索选中标签
            Showstable: [], //列表设计-表格选中标签,
            queryData: {},
            // FileType: '',//档案类型
            activeKey: "1",
            showFileUpload: false,
        };
    },

    setActiveKey: (activeKey, _) => {
        return { activeKey: activeKey };
    },

    setShowFileUpload: (showFileUpload, _) => {
        return { showFileUpload: showFileUpload };
    },

    setData: (o, state) => {
        let { data } = state;
        data = { ...data, ...o };
        return { data };
    },
    setValues: (value, state) => {
        let { values } = state;
        values = { ...values, ...value };
        return { values };
    },
    setCache: (data, state) => {
        let { cacheData } = state;
        cacheData = { ...cacheData, ...data };
        return { cacheData };
    },
    setDataLink: (data, state) => {
        let { dataLink } = state;
        dataLink = { ...dataLink, ...data };
        return { dataLink };
    },
    setFileType: (FileType, _) => {
        return { FileType };
    },
    /**
     * 设置表单信息加载完成可以渲染了
     */
    setLoadComplete: (_, _state) => {
        return { loadComplete: true };
    },
    setTask: (task, _state) => {
        return { task };
    },
    showLoading: (_, _state) => {
        return { loading: true };
    },
    hideLoading: (_, _state) => {
        return { loading: false };
    },
    /**
     * 控制重新加载组件
     */
    setReLoading: (reLoading, _state) => {
        return { reLoading };
    },
    /**
     * 重新提交
     */
    showReSubmit: () => {
        return {
            loadComplete: true,
            showSuccess: false,
            showFailed: false,
        };
    },
    /**
     * 添加成功
     */
    showSuccess: (_, _state) => {
        return { loadComplete: false, showSuccess: true, showFailed: false };
    },
    /**
     * 添加失败
     */
    showFailed: (error, _state) => {
        return {
            loadComplete: false,
            showSuccess: false,
            showFailed: true,
            error,
        };
    },
    setPrint: (print, _state) => {
        return { print };
    },
    setQueryData: (q, state) => {
        let { queryData } = state;
        queryData = { ...queryData, ...q };
        return { queryData };
    },
    initValues: (_value, _state) => {
        return { values: { data: {} } };
    },
    setManager: (Manager, _) => {
        return { Manager };
    },
    initValues: (_value, _state) => {
        return { values: { data: {} } };
    },
    syncStorage: {
        cacheData: true, //自动同步暂存数据
    },
    getForm: {
        payload: (params) => {
            //modfiy by alex 卸载组件时取消ajax请求
            return (params.ajax || sxAjax).get('api/services/app/DocumentForm/GetForm?formId=' + params.formId);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                const {
                    formId,
                    formName,
                    formType,
                    data,
                    properties,
                    fields,
                    listDesignAttrs,
                } = payload.result;
                let Showstable = [];
                if (listDesignAttrs != null) {
                    if (listDesignAttrs.tableChecks != null) {
                        for (
                            let i = 0;
                            i < listDesignAttrs.tableChecks.length;
                            i++
                        ) {
                            if (
                                listDesignAttrs.tableChecks[i].checkeds == true
                            ) {
                                listDesignAttrs.tableChecks[
                                    i
                                ].dataIndex = listDesignAttrs.tableChecks[
                                    i
                                ].dataIndex.replace("-", "_");
                                listDesignAttrs.tableChecks[
                                    i
                                ].key = listDesignAttrs.tableChecks[
                                    i
                                ].key.replace("-", "_");
                                Showstable.push(listDesignAttrs.tableChecks[i]);
                            }
                        }
                        Showstable.push({
                            checkeds: true,
                            dataIndex: "stepName",
                            key: "stepName",
                            title: "步骤名称",
                        });
                    }
                    if (listDesignAttrs.searchList != null) {
                        for (
                            let i = 0;
                            i < listDesignAttrs.searchList.length;
                            i++
                        ) {
                            listDesignAttrs.searchList[
                                i
                            ].value = listDesignAttrs.searchList[
                                i
                            ].value.replace("-", "_");
                        }
                    }
                }
                if (state.FileType != "" && fields.length > 0) {
                    let PreparationDate = fields.find(function (value) {
                        //编制日期
                        return value.customType === "PreparationDate";
                    });
                    let StartDate = fields.find(function (value) {
                        //开始日期
                        return value.customType === "StartDate";
                    });
                    let EmdDate = fields.find(function (value) {
                        //结束日期
                        return value.customType === "EmdDate";
                    });
                    let PostingStatus = fields.find(function (value) {
                        //过账状态
                        return value.customType === "PostingStatus";
                    });
                    let DocumentDate = fields.find(function (value) {
                        //凭证日期
                        return value.customType === "DocumentDate";
                    });
                    let Bookkeeper = fields.find(function (value) {
                        //记账人
                        return value.customType === "Bookkeeper";
                    });
                    let AccountBook = fields.find(function (value) {
                        //账簿页数
                        return value.customType === "AccountBook";
                    });
                    let Principal = fields.find(function (value) {
                        //负责人
                        return value.customType === "Principal";
                    });
                    let MappingStatus = fields.find(function (value) {
                        //匹配状态
                        return value.customType === "MappingStatus";
                    });
                    let BusinessType = fields.find(function (value) {
                        //业务类型
                        return value.customType === "BusinessType";
                    });
                    let BusinessDocNo = fields.find(function (value) {
                        //业务单据号
                        return value.customType === "BusinessDocNo";
                    });
                    let IsCreateVoucher = fields.find(function (value) {
                        //是否生成凭证
                        return value.customType === "IsCreateVoucher";
                    });
                    let numberField = fields.find(function (value) {
                        //编号
                        return value.customType === "number";
                    });
                    switch (state.FileType) {
                        case "原始凭证":
                            fields.map((d) => {
                                if (
                                    d.name !== "业务类型" &&
                                    d.name !== "业务单号" &&
                                    d.name !== "凭证是否生成" &&
                                    d.name !== "编号" &&
                                    d.name !== "公司名称" &&
                                    d.name !== "附件数"
                                )
                                    data[d.id].props.fieldstatus.splice(
                                        data[d.id].props.fieldstatus.findIndex(
                                            (item) => item == "1"
                                        ),
                                        1
                                    );
                                if (d.name == "附件数") {
                                    data[d.id].props.label = "文件数量";
                                    data[d.id].props.fieldstatus.push("1");
                                }
                                if (d.name == "编号")
                                    data[d.id].props.label = "凭证号";
                            });
                            break;
                        case "记账凭证":
                            //其他类型不展示的隐藏
                            data[PreparationDate?.id].props.fieldstatus.splice(
                                data[
                                    PreparationDate.id
                                ].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            ); //编制日期
                            data[StartDate.id].props.fieldstatus.splice(
                                data[StartDate.id].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            ); //开始日期
                            data[EmdDate.id].props.fieldstatus.splice(
                                data[EmdDate.id].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            ); //结束日期
                            data[AccountBook.id].props.fieldstatus.splice(
                                data[
                                    AccountBook.id
                                ].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            ); //账簿数
                            data[Principal.id].props.fieldstatus.splice(
                                data[Principal.id].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            ); //负责人

                            data[MappingStatus.id].props.fieldstatus.splice(
                                data[
                                    MappingStatus.id
                                ].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            );
                            data[BusinessType.id].props.fieldstatus.splice(
                                data[
                                    BusinessType.id
                                ].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            );
                            data[BusinessDocNo.id].props.fieldstatus.splice(
                                data[
                                    BusinessDocNo.id
                                ].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            );
                            data[IsCreateVoucher.id].props.fieldstatus.splice(
                                data[
                                    IsCreateVoucher.id
                                ].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            );
                            data[numberField.id].props.label = "凭证号";
                            break;
                        case "总账":
                        case "银行日记账":
                        case "现金日记账":
                        case "明细账":
                            //其他类型不展示的隐藏
                            data[PreparationDate.id].props.fieldstatus.splice(
                                data[
                                    PreparationDate.id
                                ].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            ); //编制日期
                            data[PostingStatus.id].props.fieldstatus.splice(
                                data[
                                    PostingStatus.id
                                ].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            ); //过账状态
                            //data[DocumentDate.id].props.fieldstatus.splice(data[DocumentDate.id].props.fieldstatus.findIndex(item => item == "1"), 1);//凭证日期
                            data[Bookkeeper.id].props.fieldstatus.splice(
                                data[Bookkeeper.id].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            );

                            data[MappingStatus.id].props.fieldstatus.splice(
                                data[
                                    MappingStatus.id
                                ].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            );
                            data[BusinessType.id].props.fieldstatus.splice(
                                data[
                                    BusinessType.id
                                ].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            );
                            data[BusinessDocNo.id].props.fieldstatus.splice(
                                data[
                                    BusinessDocNo.id
                                ].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            );
                            data[IsCreateVoucher.id].props.fieldstatus.splice(
                                data[
                                    IsCreateVoucher.id
                                ].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            );
                            data[DocumentDate.id].props.label = "账簿日期";
                            break;
                        case "EBS月度报表":
                        case "EBS季度报表":
                        case "EBS年度报表":
                        case "合并报表":
                            //其他类型不展示的隐藏
                            data[PostingStatus.id].props.fieldstatus.splice(
                                data[
                                    PostingStatus.id
                                ].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            ); //过账状态
                            //data[DocumentDate.id].props.fieldstatus.splice(data[DocumentDate.id].props.fieldstatus.findIndex(item => item == "1"), 1);//凭证日期
                            data[Bookkeeper.id].props.fieldstatus.splice(
                                data[Bookkeeper.id].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            );
                            data[AccountBook.id].props.fieldstatus.splice(
                                data[
                                    AccountBook.id
                                ].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            ); //账簿数

                            data[MappingStatus.id].props.fieldstatus.splice(
                                data[
                                    MappingStatus.id
                                ].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            );
                            data[BusinessType.id].props.fieldstatus.splice(
                                data[
                                    BusinessType.id
                                ].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            );
                            data[BusinessDocNo.id].props.fieldstatus.splice(
                                data[
                                    BusinessDocNo.id
                                ].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            );
                            data[IsCreateVoucher.id].props.fieldstatus.splice(
                                data[
                                    IsCreateVoucher.id
                                ].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            );
                            data[DocumentDate.id].props.label = "报表日期";
                            break;
                        case "纳税申报表":
                        case "审计报告":
                        case "银行对账单":
                        case "银行回单":
                            //其他类型不展示的隐藏
                            data[PostingStatus.id].props.fieldstatus.splice(
                                data[
                                    PostingStatus.id
                                ].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            ); //过账状态
                            //data[DocumentDate.id].props.fieldstatus.splice(data[DocumentDate.id].props.fieldstatus.findIndex(item => item == "1"), 1);//凭证日期
                            data[Bookkeeper.id].props.fieldstatus.splice(
                                data[Bookkeeper.id].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            );
                            data[AccountBook.id].props.fieldstatus.splice(
                                data[
                                    AccountBook.id
                                ].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            ); //账簿数

                            data[MappingStatus.id].props.fieldstatus.splice(
                                data[
                                    MappingStatus.id
                                ].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            );
                            data[BusinessType.id].props.fieldstatus.splice(
                                data[
                                    BusinessType.id
                                ].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            );
                            data[BusinessDocNo.id].props.fieldstatus.splice(
                                data[
                                    BusinessDocNo.id
                                ].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            );
                            data[IsCreateVoucher.id].props.fieldstatus.splice(
                                data[
                                    IsCreateVoucher.id
                                ].props.fieldstatus.findIndex(
                                    (item) => item == "1"
                                ),
                                1
                            );
                            data[DocumentDate.id].props.label = "报告日期";
                            break;
                    }
                }
                let result = {
                    formId,
                    formName,
                    formType,
                    data,
                    fields,
                    Showstable,
                    searchList:
                        listDesignAttrs != null
                            ? listDesignAttrs.searchLists != null
                                ? listDesignAttrs.searchLists
                                : []
                            : [],
                };
                if (properties) {
                    result.properties = properties;
                }
                return result;
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },
    getFormViewPermission: {
        payload: (params) => {
            return sxAjax.get(
                "api/services/app/AppObject/GetAppPermissionRoles",
                params
            );
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                const data = payload.result;
                return { viewPermission: data };
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },
    getFormEventId: {
        payload: (params) => {
            return sxAjax.get("api/services/app/AppObject/GetEventId", params);
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                const eventId = payload.result;
                return { eventId };
            },
            complete: (state, action) => {
                return {};
            },
        },
    },
    getGuid: {
        payload: (params) => {
            return sxAjax.get('uuid/get', params);
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                return ({});
            },
            complete: (state, action) => {
                return ({});
            },
        }
    },
    saveFormData: {
        payload: (params) => {
            return sxAjax.post(
                "api/services/app/AppObject/SaveFormData",
                params
            );
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                // const data = payload.result;
                return {};
            },
            complete: (state, action) => {
                return {};
            },
        },
    },
    SaveFormDataBatch: {
        //批量保存表单
        payload: (params) => {
            return sxAjax.post(
                "api/services/app/AppObject/SaveFormDataBatch",
                params.AppFormDataDto
            );
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                // const data = payload.result;
                return {};
            },
            complete: (state, action) => {
                return {};
            },
        },
    },
    getFormData: {
        payload: (params) => {
            return sxAjax.get("api/services/app/AppObject/GetFormData", params);
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                const { mstId, formData, formName, formId } = payload.result;
                return { mstId, values: formData, formName, formId };
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },
    getProcessFormData: {
        payload: (params) => {
            return sxAjax.get(
                "api/services/app/AppObject/GetProcessFormData",
                params
            );
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                const { mstId, formData } = payload.result;
                return { mstId, values: formData };
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },
    getProcessInfo: {
        payload: (params) => {
            return sxAjax.get(
                abp.setting.get("App.Api.Workflow.Host") +
                    "api/services/app/Process/GetProcessInfo?processName=" +
                    params.processName +
                    "&version=" +
                    params.version
            );
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                const process = payload.result;
                return { process };
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },
    createTask: {
        payload: (params) => {
            // return sxAjax.post(abp.setting.get("App.Api.Workflow.Host") + 'api/services/app/Task/start', params);
            // return sxAjax.post("api/services/app/AppObject/StartTask", params);
            return sxAjax.post("api/services/app/ApplicationForm/CreateWorkFlow", params);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                return {};
            },
            complete: (state, action) => {
                return {};
            },
        },
    },
    submitTask: {
        payload: (params) => {
            return sxAjax.post(
                abp.setting.get("App.Api.Workflow.Host") +
                    "api/services/app/Task/Submit",
                params
            );
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                return {};
            },
            complete: (state, action) => {
                return {};
            },
        },
    },
    AbortTask: {
        payload: (params) => {
            return sxAjax.post(
                abp.setting.get("App.Api.Workflow.Host") +
                    "api/services/app/Task/Abort",
                params
            );
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                return {};
            },
            complete: (state, action) => {
                return {};
            },
        },
    },
    ReturnTask: {
        payload: (params) => {
            return sxAjax.post(
                abp.setting.get("App.Api.Workflow.Host") +
                    "api/services/app/Task/Return",
                params
            );
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                return {};
            },
            complete: (state, action) => {
                return {};
            },
        },
    },
    AssignTask: {
        payload: (params) => {
            return sxAjax.post(
                abp.setting.get("App.Api.Workflow.Host") +
                    "api/services/app/Task/Assign",
                params
            );
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                return {};
            },
            complete: (state, action) => {
                return {};
            },
        },
    },
    setFormType: (formType) => {
        return { formType: formType };
    },
    CanRescinded: {
        payload: (params) => {
            return sxAjax.get(abp.setting.get("App.Api.Workflow.Host") + "api/services/app/Task/CanRescinded?incidentId=" + params.incidentId);
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                return {};
            },
            complete: (state, action) => {
                return {};
            },
        },
    },
    /**
     * 申请人撤销流程
     */
    Rescinded: {
        payload: (params) => {
            return sxAjax.post(
                abp.setting.get("App.Api.Workflow.Host") +
                    "api/services/app/Task/Rescinded",
                params
            );
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                return {};
            },
            complete: (state, action) => {
                return {};
            },
        },
    },
    /**
     * 抄送设置为已读
     */
    SetCcAsRead: {
        payload: (params) => {
            return sxAjax.post(
                abp.setting.get("App.Api.Workflow.Host") +
                    "api/services/app/Task/SetCcAsRead",
                params
            );
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                return {};
            },
            complete: (state, action) => {
                return {};
            },
        },
    },
    /**
     * 设置所有的抄送为已读
     */
    SetAllCcAsRead: {
        payload: (params) => {
            return sxAjax.post(
                abp.setting.get("App.Api.Workflow.Host") +
                    "api/services/app/Task/SetAllCcAsRead",
                params
            );
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                return {};
            },
            complete: (state, action) => {
                return {};
            },
        },
    },
    getFormDataRecord: {
        payload: (params) => {
            return sxAjax.get('api/services/app/EntityChange/GetEntityChanges', params);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                return ({ formDataChangeHistory: payload.result });
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    delFormData: {
        payload: (params) => {
            return sxAjax.del('api/services/app/AppObject/deleteFormData', params);
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                const data = payload.result;
                return ({ formAllData: data });
            },
            complete: (state, action) => {
                return ({});
            },
        }
    },
    deleteIncident: {
        payload: (params) => {
            return sxAjax.del(abp.setting.get("App.Api.Workflow.Host") + 'api/services/app/Incident/DeleteIncident', params);
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {

            },
            complete: (state, action) => {
                return ({});
            },
        }
    },
    ImportData: {
        payload: (params) => {
            return sxAjax.post('Excel/ImportData', params);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                const { data } = payload.result;

                return ({});
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
}
